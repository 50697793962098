export const updateDeviceType = (deviceType) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_DEVICE_TYPE",
        payload: {
          deviceType: deviceType,
        },
      });
    } catch (err) {}
  };
};

export const updateDeviceSize = (deviceSize) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_DEVICE_SIZE",
        payload: {
          deviceSize: deviceSize,
        },
      });
    } catch (err) {}
  };
};

export const updateSuccessType = (successType) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_SUCCESS_TYPE",
        payload: {
          successType: successType,
        },
      });
    } catch (err) {}
  };
};
