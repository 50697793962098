import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//Style
import { RowContainer, VerticalContainer, Overlay } from "../global/Layout";
import { Green, Black } from "../global/Color";
import { Title1, SpecTitle1, SubTitle5, Headline } from "../global/Typography";
//Components
import MenuOptions from "./MenuOptions";
//Assets
import gingerbread from "../assets/gingerbread-man.png";
import appStore from "../assets/app-store.png";
import playStore from "../assets/play-store.png";
import ratVector from "../assets/rat.png";
import logoLineUps from "../assets/logo-line-ups.png";
import { GiHamburgerMenu } from "react-icons/gi";

class Header extends Component {
  state = {
    menuBarOpen: false,
  };

  handleNavigate = (path) => {
    switch (path) {
      case "newPurchase":
        this.props.history.push("/new-purchase");
        break;
      case "redeem":
        this.props.history.push("/redeem-newusers");
        break;
      case "support":
        this.props.history.push("/support");
        break;
      case "home":
        this.props.history.push("/");
        break;
      default:
        break;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleMenuOpen = (bool) => {
    this.setState({
      menuBarOpen: bool,
    });
  };

  renderMenuItems = () => {
    return (
      <RowContainer>
        <Headline
          style={{ marginLeft: 50 }}
          onClick={this.handleNavigate.bind(this, "newPurchase")}
        >
          Purchase or Subscribe
        </Headline>
        <Headline
          style={{ marginLeft: 50 }}
          onClick={this.handleNavigate.bind(this, "redeem")}
        >
          Redeem
        </Headline>
        <Headline
          style={{ marginLeft: 50 }}
          onClick={this.handleNavigate.bind(this, "support")}
        >
          Support
        </Headline>
      </RowContainer>
    );
  };

  renderMenu = () => {
    if (this.props.utilsStore.deviceType <= 1)
      return <HamburgerIcon onClick={this.handleMenuOpen.bind(this, true)} />;
    else return this.renderMenuItems();
  };

  renderMobileMenu = () => {
    return (
      <Overlay>
        <Title1
          onClick={this.handleMenuOpen.bind(this, false)}
          style={{ marginTop: 30, alignSelf: "flex-end" }}
        >
          X
        </Title1>
        <MenuOptions />
        <VerticalContainer style={{ width: "100%" }}>
          <img
            src={logoLineUps}
            style={{ width: 280 }}
            alt="Me Books App Logo"
          />
          <RowContainer>
            <VerticalContainer>
              <img
                src={appStore}
                style={{ width: 120, marginTop: -20 }}
                alt="Button Linked to Me Books App in App Store"
                onClick={() => {
                  window.open("https://apps.apple.com/us/app/id1510382298");
                }}
              />
              <img
                src={playStore}
                style={{ width: 120, marginTop: 10 }}
                alt="Button Linked to Me Books App in Play Store"
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=co.mebooks.plus"
                  );
                }}
              />
            </VerticalContainer>
            <img src={ratVector} alt="Rat Vector" />
          </RowContainer>
        </VerticalContainer>
      </Overlay>
    );
  };

  render() {
    return (
      <HeaderContainer>
        <RowContainer
          style={{
            marginTop: this.props.utilsStore.deviceType < 1 ? 10 : 30,
            cursor: "pointer",
          }}
          onClick={this.handleNavigate.bind(this, "home")}
        >
          <img
            style={{
              width: this.props.utilsStore.deviceType < 1 ? 70 : 90,
              zIndex: 5,
            }}
            src={gingerbread}
            alt="Me Books Co Logo"
          />
          <VerticalContainer
            style={{
              alignItems: "flex-start",
            }}
          >
            <SpecTitle1 style={{ color: Black }}>Me Books</SpecTitle1>
            <SubTitle5 style={{ color: Green, marginTop: -25 }}>
              Purchase / Redeem Gifts
            </SubTitle5>
          </VerticalContainer>
        </RowContainer>

        <div style={{ flexGrow: 1 }} />
        {this.renderMenu()}
        {this.props.utilsStore.deviceType <= 1 &&
          this.state.menuBarOpen &&
          this.renderMobileMenu()}
      </HeaderContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps, null)(withRouter(Header));

const HeaderContainer = styled(RowContainer)`
  height: 100px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: space-around;
`;

const HamburgerIcon = styled(GiHamburgerMenu)`
  font-size: 30px;
  cursor: pointer;
  color: ${Green};
  margin-right: 10px;
`;
