import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
//Pages
import HomePage from "./pages/HomePage";
import NewUsersRedeem from "./pages/NewUsersRedeem";
import ActionSuccess from "./pages/ActionSuccess";
import NewPurchase from "./pages/NewPurchase";
import ExistingUserRedeem from "./pages/ExistingUserRedeem";
import Support from "./pages/Support";
import ResetPassword from "./pages/ResetPassword";
import NotFoundPage from "./pages/NotFoundPage";
//Utils
import ReactGA from "react-ga";
//Redux Store
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

//Store Creation
const initialState = {};
const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware))
);

export default class App extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-145714156-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/redeem-newusers" component={NewUsersRedeem} />
            <Route
              exact
              path="/redeem-existing"
              component={ExistingUserRedeem}
            />
            <Route exact path="/action-success" component={ActionSuccess} />
            <Route exact path="/new-purchase" component={NewPurchase} />
            <Route exact path="/support" component={Support} />
            <Route path="/auth" component={ResetPassword} />
            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}
