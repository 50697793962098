import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import {
  BigTitle,
  Title1,
  SubTitle5,
  SubBigTitle,
  SubTitle2,
} from "../global/Typography";
import {
  VerticalContainer,
  FlexibleRowContainer,
  minMobileBreakpoints,
  minTabletBreakpoints,
} from "../global/Layout";
import { Purple } from "../global/Color";
//Assets
import successImg from "../assets/two-sisters.png";
import appStore from "../assets/app-store.png";
import playStore from "../assets/play-store.png";
import summaryPigPic from "../assets/summary-pic.png";
import successPicLion from "../assets/success-pic-lion.png";

class SuccessVisual extends Component {
  renderContentCard = () => {
    return (
      <ContentCard style={{ marginBottom: 20 }}>
        <img
          style={{
            width: this.props.utilsStore.deviceType >= 1 ? 320 : 240,
            marginTop: -130,
          }}
          src={summaryPigPic}
          alt="Summary Image"
        />
        <Title1>Woohoo!!!</Title1>
        <SubTitle5
          style={{ marginTop: -30, maxWidth: "90%", textAlign: "center" }}
        >
          You're one step away to nurture the next storyteller.
        </SubTitle5>
        <BigTitle style={{ marginTop: 20 }}>
          {this.props.selectedPlanAttribute
            ? this.props.selectedPlanAttribute.title
            : ""}
        </BigTitle>
        <SubTitle5 style={{ marginTop: -35 }}>Subscription Plan</SubTitle5>
        <BigTitle style={{ marginTop: 30 }}>
          {this.props.selectedPlanAttribute
            ? this.props.selectedPlanAttribute.priceTitle
            : ""}
        </BigTitle>
        <SubTitle5 style={{ marginTop: -35 }}>
          {this.props.selectedPlanAttribute
            ? this.props.selectedPlanAttribute.content
            : ""}
        </SubTitle5>
      </ContentCard>
    );
  };

  renderSuccessCard = () => {
    return (
      <ContentCard>
        <img
          style={{
            width: this.props.utilsStore.deviceType >= 1 ? 320 : 240,
            marginTop: -170,
          }}
          src={successPicLion}
          alt="Success Lion Logo"
        />
        <Title1>Successfully</Title1>
        <SubTitle5>Purchased and Gift code will be sent to</SubTitle5>
        <SubTitle2>{this.props.email}</SubTitle2>
      </ContentCard>
    );
  };

  renderSubscriptionTitle = () => {
    let subscriptionType = "month";
    if (this.props.utilsStore.successType.data.type === 0)
      subscriptionType = "week";
    else if (this.props.utilsStore.successType.data.type > 1)
      subscriptionType = "months";

    let subscriptionValue = 1;
    if (this.props.utilsStore.successType.data.type > 1)
      subscriptionValue = this.props.utilsStore.successType.data.type;

    return (
      <>
        {subscriptionValue}-{subscriptionType}
      </>
    );
  };

  renderRedemptionSuccess = () => {
    return (
      <ContentCard>
        <img
          style={{
            width: this.props.utilsStore.deviceType >= 1 ? 320 : 240,
            marginTop: -130,
          }}
          src={successImg}
          alt="success.png"
        />
        <Title1 style={{ marginTop: 50 }}>Hooray!!!</Title1>
        <SubTitle5 style={{ marginTop: -30 }}>
          You have successfully redeemed your
        </SubTitle5>

        {this.props.utilsStore.successType &&
          this.props.utilsStore.successType.data && (
            <>
              <BigTitle style={{ marginTop: 50 }}>
                {this.renderSubscriptionTitle()}
              </BigTitle>
              <SubTitle5 style={{ marginTop: -30 }}>
                Subscription Plan
              </SubTitle5>
            </>
          )}

        {this.props.utilsStore.successType &&
        this.props.utilsStore.successType.type === "existing" ? (
          <>
            <BigTitle style={{ marginTop: 50 }}>New Expiry Date</BigTitle>
            <SubTitle5 style={{ marginTop: -30 }}>
              {new Date(
                this.props.utilsStore.successType.data.date
              ).toDateString()}
            </SubTitle5>
          </>
        ) : (
          <>
            <SubBigTitle style={{ marginTop: 50 }}>Download @</SubBigTitle>
            <FlexibleRowContainer style={{ marginTop: -10 }}>
              <img
                src={appStore}
                style={{ width: 180 }}
                alt="Button Linked to Me Books App in App Store"
                onClick={() => {
                  window.open("https://apps.apple.com/us/app/id1510382298");
                }}
              />
              <img
                src={playStore}
                style={{
                  width: 180,
                  marginLeft: this.props.utilsStore.deviceType === 0 ? 0 : 10,
                  marginTop: this.props.utilsStore.deviceType === 0 ? 10 : 0,
                }}
                alt="Button Linked to Me Books App in Play Store"
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=co.mebooks.plus"
                  );
                }}
              />
            </FlexibleRowContainer>
          </>
        )}
      </ContentCard>
    );
  };

  render() {
    if (this.props.type) {
      if (this.props.type === "success") return this.renderSuccessCard();
      else return this.renderContentCard();
    } else return this.renderRedemptionSuccess();
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(SuccessVisual);

const ContentCard = styled(VerticalContainer)`
  margin-top: 100px;
  margin-bottom: 50px;
  width: 50%;
  max-width: 600px;
  background-color: ${Purple};
  border-radius: 30px;
  padding: 60px 0px;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    width: 75%;
  }

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    width: 85%;
  }
`;
