import styled from "styled-components";
import { Green } from "./Color";
import { Input, Form, Select } from "antd";
const { Option } = Select;

export const minBigLaptopBreakpoints = 1200;
export const minLaptopBreakpoints = 992;
export const minTabletBreakpoints = 768;
export const minMobileBreakpoints = 576;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BackgroundContainer = styled(VerticalContainer)`
  background-color: #1d090a;
`;

export const Overlay = styled(VerticalContainer)`
  z-index: 5;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 30px;
  background ${Green};
`;

export const FlexibleRowContainer = styled(RowContainer)`
  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    flex-direction: column;
  }
`;

export const FlexibleVerticalContainer = styled(VerticalContainer)`
  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    flex-direction: row;
  }
`;

export const Button = styled.button`
  background-color: #4cb496;
  border-radius: 28px;
  color: #ffffff;
  border: none;
  font-family: DoctorSoosBold;
  font-size: 20px;
  padding: 5px 15px;
  cursor: pointer;
  outline: inherit;
  width: 150px;
  height: 55px;
  margin: 5px;

  &:hover {
    background-color: #5be2bb;
  }
`;

export const FormItem = styled(Form.Item)`
  width: 280px;
`;

export const FormInput = styled(Input)`
  text-align: left;
  font-family: VisbyRound;
  font-size: 14px;
  padding: 0px 25px;
  width: 100% !important;
`;

export const FormSelect = styled(Select)`
  text-align: center !important;
  font-size: 14px;
  font-family: VisbyRound !important;
  width: 100% !important;
`;
