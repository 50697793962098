import React, { Component } from "react";
//Styles
import { BackgroundContainer } from "../global/Layout";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import SuccessVisual from "../components/SuccessVisual";

class RedeemSuccess extends Component {
  render() {
    return (
      <>
        <WindowResizer />
        <Header />
        <BackgroundContainer>
          <SuccessVisual />
        </BackgroundContainer>
        <Footer />
      </>
    );
  }
}

export default RedeemSuccess;
