import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
//Style
import { SubTitle4, Headline } from "../global/Typography";
import { White } from "../global/Color";

let list = [
  {
    header: "New Users",
    line1: "Purchase a Gift Subscription or Subscribe here",
    line2: "Redeem Your Gift Subscription",
    link1: "newPurchase",
    link2: "redeemNewUser",
    externalLink1: null,
    externalLnk2: null,
  },
  {
    header: "Existing Me Books Users",
    line1: "Add to Your Existing Account",
    line2: "How to use Me Books App",
    link1: "redeemExisting",
    link2: null,
    externalLnk1: null,
    externalLink2: "https://www.mebooks.co/about",
  },
  {
    header: "Supports",
    line1: "Contact Us",
    line2: "Turn off Apple’s auto-renew Guide",
    link1: null,
    link2: "autoRenew",
    externalLink1: "https://www.mebooks.co/contact-us",
    externalLink2: null,
  },
];

class MenuOptions extends Component {
  handleNavigate = (path) => {
    switch (path) {
      case "redeemNewUser":
        this.props.history.push("/redeem-newusers");
        break;
      case "newPurchase":
        this.props.history.push("/new-purchase");
        break;
      case "redeemExisting":
        this.props.history.push("/redeem-existing");
        break;
      case "autoRenew":
        this.props.history.push("/support");
        break;
      case "home":
        this.props.history.push("/");
        break;
      default:
        break;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  renderMenuTitleList = (details) => {
    return (
      <>
        <SubTitle4>{details.header}</SubTitle4>
        <CustomHeadline
          style={{ textAlign: "left" }}
          href={details.externalLink1}
          target="_blank"
          onClick={this.handleNavigate.bind(this, details.link1)}
        >
          {details.line1}
        </CustomHeadline>
        <CustomHeadline
          href={details.externalLink2}
          target="_blank"
          onClick={this.handleNavigate.bind(this, details.link2)}
        >
          {details.line2}
        </CustomHeadline>
      </>
    );
  };

  render() {
    return (
      <>
        {this.renderMenuTitleList(list[0])}
        {this.renderMenuTitleList(list[1])}
        {this.renderMenuTitleList(list[2])}
        <CustomHeadline
          href="https://www.mebooks.co/"
          target="_blank"
          onClick={this.handleNavigate.bind(this, "home")}
        >
          Return to Me Books Homepage
        </CustomHeadline>
      </>
    );
  }
}

export default withRouter(MenuOptions);

const CustomHeadline = styled(Headline)`
  color: ${White} !important;
`;
