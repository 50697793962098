import React, { Component } from "react";
import styled from "styled-components";
//Styles
import { Spin } from "antd";
import { Overlay } from "../global/Layout";
import { Black } from "../global/Color";
import { Body } from "../global/Typography";

class Loading extends Component {
  render() {
    return (
      <LoadingContainer>
        <Spin style={{ marginBottom: 10 }} size="large" />
        <Body style={{ color: Black }}>Loading...</Body>
      </LoadingContainer>
    );
  }
}

export default Loading;

const LoadingContainer = styled(Overlay)`
  align-items: center;
  justify-content: center;
  background: rgba(247, 247, 252, 0.95);
`;
