import React, { Component } from "react";
import styled from "styled-components";
//Styles
import { RowContainer } from "../global/Layout";
import { Title5 } from "../global/Typography";
import { Green, Red, White } from "../global/Color";

let circleSize = 8;

export default class Stepper extends Component {
  state = {
    dividerWidth: 0,
  };

  componentDidMount() {
    this.handleCalculateDividerWidth();
    window.addEventListener("resize", this.handleCalculateDividerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCalculateDividerWidth);
  }

  handleCalculateDividerWidth = () => {
    let dividerWidth =
      (document.getElementById("stepper").parentElement.clientWidth -
        12 * this.props.steps.length) /
      (this.props.steps.length - 1);
    this.setState({
      dividerWidth: dividerWidth,
    });
  };

  renderColorSelection = (index) => {
    if (index === 3) return Green;
    else return Red;
  };

  renderStepDividers = () => {
    let stepsDivider = [];
    let maxLength = this.props.steps.length;

    this.props.steps.map((eachStep, index) => {
      stepsDivider.push(
        <RowContainer style={{ position: "relative" }}>
          <Circle
            style={
              index <= this.props.current
                ? { backgroundColor: this.renderColorSelection(index) }
                : {}
            }
          />
          <DividerTitle
            style={
              index === this.props.current
                ? {
                    fontSize: 18,
                    color: this.renderColorSelection(index),
                    top: -23,
                  }
                : {}
            }
          >
            {eachStep}
          </DividerTitle>
          {maxLength - 1 !== index && (
            <Divider
              style={{
                width: this.state.dividerWidth,
                left: 10,
                backgroundColor:
                  index <= this.props.current - 1
                    ? this.renderColorSelection(index)
                    : White,
              }}
            />
          )}
        </RowContainer>
      );
    });

    return stepsDivider;
  };

  render() {
    return (
      <RowContainer
        id="stepper"
        style={{
          width: "100%",
          marginTop: 40,
          justifyContent: "space-between",
        }}
      >
        {this.renderStepDividers()}
      </RowContainer>
    );
  }
}

const Circle = styled.div`
  background-color: ${White};
  height: ${circleSize}px;
  width: ${circleSize}px;
  border-radius: ${circleSize}px;
`;

const Divider = styled.div`
  height: 3px;
  border-radius: 8px;
  position: absolute;
`;

const DividerTitle = styled(Title5)`
  position: absolute;
  top: -20px;
`;
