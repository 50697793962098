import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//Style
import { VerticalContainer, RowContainer } from "../global/Layout";
import { White, Brown } from "../global/Color";
import { Headline, Footnote } from "../global/Typography";
//Components
import MenuOptions from "./MenuOptions";
//antd
import { Divider } from "antd";
//Assets
import ratVector from "../assets/rat.png";
import logoLineUps from "../assets/logo-line-ups.png";
import appStore from "../assets/app-store-white.png";
import playStore from "../assets/play-store-white.png";

class Footer extends Component {
  handleNavigate = () => {
    this.props.history.push("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  renderFooterContent = () => {
    return (
      <VerticalContainer
        style={{
          alignItems: "flex-start",
        }}
      >
        <MenuOptions />
      </VerticalContainer>
    );
  };

  render() {
    return (
      <FooterContainer>
        <VerticalContainer>
          <img
            src={logoLineUps}
            style={{ width: 280 }}
            alt="Me Books Year Logo"
          />
          <RowContainer style={{ marginTop: 20 }}>
            <img
              src={appStore}
              style={{ width: 120, cursor: "pointer" }}
              alt="Button Linked to Me Books App in App Store"
              onClick={() => {
                window.open("https://apps.apple.com/us/app/id1510382298");
              }}
            />
            <img
              src={playStore}
              style={{ width: 120, cursor: "pointer", marginLeft: 10 }}
              alt="Button Linked to Me Books App in Play Store"
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=co.mebooks.plus"
                );
              }}
            />
          </RowContainer>
        </VerticalContainer>
        <VerticalContainer style={{ width: "90%", marginTop: "10%" }}>
          <CustomHeadline onClick={this.handleNavigate}>
            Return to Me Books Homepage
          </CustomHeadline>
          <CustomDivider />
          <RowContainer
            style={{
              width: "95%",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginBottom: 30,
            }}
          >
            {this.props.utilsStore.deviceType >= 2 ? (
              <>
                {this.renderFooterContent()}
                <VerticalContainer style={{ alignItems: "flex-end" }}>
                  <img src={ratVector} alt="Rat Vector" />
                  <RowContainer>
                    <Footnote>
                      Copyright Me Books. All Rights Reserved
                      <CustomHeadline
                        href="https://www.mebooks.co/terms-conditions"
                        target="_blank"
                        style={{
                          fontSize: 12,
                          fontFamily: "VisbyRound",
                          marginRight: 10,
                          marginLeft: 10,
                        }}
                      >
                        Terms and Conditions
                      </CustomHeadline>
                      <CustomHeadline
                        href="https://www.mebooks.co/privacy"
                        target="_blank"
                        style={{ fontSize: 12, fontFamily: "VisbyRound" }}
                      >
                        Privacy Policy
                      </CustomHeadline>
                    </Footnote>
                  </RowContainer>
                </VerticalContainer>
              </>
            ) : (
              <VerticalContainer style={{ width: "100%" }}>
                <RowContainer>
                  <Footnote>Copyright Me Books. All Rights Reserved</Footnote>
                </RowContainer>
                <RowContainer>
                  <CustomHeadline
                    href="https://www.mebooks.co/terms-conditions"
                    target="_blank"
                    style={{
                      fontSize: 12,
                      fontFamily: "VisbyRound",
                      marginRight: 10,
                    }}
                  >
                    Terms and Conditions
                  </CustomHeadline>
                  <CustomHeadline
                    href="https://www.mebooks.co/privacy"
                    target="_blank"
                    style={{ fontSize: 12, fontFamily: "VisbyRound" }}
                  >
                    Privacy Policy
                  </CustomHeadline>
                </RowContainer>
              </VerticalContainer>
            )}
          </RowContainer>
        </VerticalContainer>
      </FooterContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(withRouter(Footer));

const FooterContainer = styled(VerticalContainer)`
  background-color: ${Brown};
  margin-top: -10px;
  z-index: 20;
`;

const CustomDivider = styled(Divider)`
  height: 2px !important;
  background-color: ${White};
  margin: 2% !important;
`;

const CustomHeadline = styled(Headline)`
  color: ${White} !important;
  text-decoration: underline;
`;
