import React, { Component } from "react";
import styled from "styled-components";
//Styles
import { SubTitle5, Headline } from "../global/Typography";
import { White } from "../global/Color";
import {
  VerticalContainer,
  RowContainer,
  BackgroundContainer,
} from "../global/Layout";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import RedeemForm from "../components/RedeemForm";
import WindowResizer from "../components/WindowResizer";

class ExistingUserRedeem extends Component {
  state = {
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    code: "",
    codeError: "",
    country: "",
    countryError: "",
    user: null,
  };

  handleNavigate = () => {
    this.props.history.push({
      pathname: "/action-success",
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    return (
      <>
        <WindowResizer />
        <Header />
        <BackgroundContainer>
          <RedeemForm type="existing" handleNavigate={this.handleNavigate} />
          {/* <Footnote style={{ marginTop: 10, textAlign: "center" }}>
              If you are currently subscribed through Apple,
            </Footnote>
            <FlexibleRowContainer>
              <Footnote>
                you will need to turn off Apple's auto-renew. &nbsp;
              </Footnote>
              <a
                href="https://support.apple.com/en-us/HT202039"
                target="_blank"
              >
                <TitleLink
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  Learn how.
                </TitleLink>
              </a>
            </FlexibleRowContainer> */}
          <SubTitle5 style={{ marginTop: 30 }}>Need Help?</SubTitle5>
          <RowContainer style={{ marginBottom: 50 }}>
            <SubTitle5>hello@mebooks.co or &nbsp;</SubTitle5>
            <CustomHeadline
              href="https://www.mebooks.co/contact-us"
              target="_blank"
            >
              contact us
            </CustomHeadline>
            <SubTitle5> &nbsp; here.</SubTitle5>
          </RowContainer>
        </BackgroundContainer>
        <Footer />
      </>
    );
  }
}

const CustomHeadline = styled(Headline)`
  color: ${White} !important;
  text-decoration: underline;
  margin-top: -5px;
`;

export default ExistingUserRedeem;
