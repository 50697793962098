import * as firebase from "firebase/app";
import "firebase/auth";

let firebaseConfig = {
  apiKey: "AIzaSyCZNOcDlD1FGQjdeYqmhyRqYeSqqZZasYM",
  authDomain: "mebooks-plus.firebaseapp.com",
  databaseURL: "https://mebooks-plus.firebaseio.com",
  projectId: "mebooks-plus",
  storageBucket: "mebooks-plus.appspot.com",
  messagingSenderId: "150785843802",
  appId: "1:150785843802:web:cacbb9cf4b953ad2216e63",
  measurementId: "G-X0GBK69B59"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
