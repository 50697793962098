import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import { SpecTitle1, SubTitle2, SubBody } from "../global/Typography";
import {
  VerticalContainer,
  BackgroundContainer,
  FlexibleRowContainer,
} from "../global/Layout";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
//Assets
import appleLogo from "../assets/apple-logo.png";
import googlePlayLogo from "../assets/goolgeplay-logo.png";

class Support extends Component {
  renderRowAttributes = (attributes) => {
    if (attributes === "maxWidth") return 750;
    else {
      if (this.props.utilsStore.deviceType > 1) return "65%";
      else if (this.props.utilsStore.deviceType > 0) return "80%";
      else return "90%";
    }
  };

  render() {
    return (
      <>
        <WindowResizer />
        <Header />
        <BackgroundContainer>
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              marginTop: 60,
              width: this.renderRowAttributes(),
              maxWidth: this.renderRowAttributes("maxWidth"),
            }}
          >
            <SpecTitle1>How to turn off Apple or Google</SpecTitle1>
            <SubTitle2>Auto-renew Subscriptions?</SubTitle2>
            <FlexibleRowContainer
              style={{
                marginTop: 30,
                width: "100%",
              }}
            >
              <VerticalContainer style={{ alignItems: "flex-start" }}>
                <img
                  src={appleLogo}
                  style={{ width: 30, marginBottom: 25 }}
                  alt="apple_logo.png"
                />
                <Description>
                  If a member is currently subscribed through Apple, the
                  recipient can redeem their gift subscription and apply it to
                  their existing Me Books account, but will also need to turn
                  off Apple's auto-renew to avoid getting charged.
                  <br />
                  <br /> Here are more details on how to do that:
                  <a
                    href="https://support.apple.com/en-us/HT202039"
                    target="_blank"
                  >
                    <Description
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      View, change, or cancel your subscriptions on iTunes.
                    </Description>
                  </a>
                  <br />
                  <br />
                  At the end of the gift subscription duration, please be sure
                  to resubscribe in your iTunes settings or on the Me Books
                  website to continue to enjoy using <br /> Me Books App.
                </Description>
              </VerticalContainer>
              <div style={{ flexGrow: 1 }} />
              <VerticalContainer
                style={{
                  alignItems: "flex-start",
                  marginTop: this.props.utilsStore.deviceType < 1 ? 30 : 0,
                }}
              >
                <img
                  src={googlePlayLogo}
                  style={{ marginBottom: 25 }}
                  alt="google_store_logo.png"
                />
                <Description>
                  If the member is currently subscribed through GooglePlay, the
                  recipient can redeem the gift code and apply it to their
                  existing Me Books account, but will also need to turn off
                  GooglePlay's auto-renew to avoid getting charged.
                  <br />
                  <br />
                  Here are more details on how to do that: Cancel or change a
                  subscription on Google Play. (The recipient must be signed in
                  with the Google account that was used to subscribe to Me
                  Books.)
                  <br />
                  <br /> At the end of the gift subscription duration, please be
                  sure to turn on the auto-renew in your Google Play account or
                  resubscribe on the Me Books website to continue to enjoy using
                  Me Books App.
                </Description>
              </VerticalContainer>
            </FlexibleRowContainer>
          </VerticalContainer>
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              marginTop: 50,
              marginBottom: 40,
              width: this.renderRowAttributes(),
              maxWidth: this.renderRowAttributes("maxWidth"),
            }}
          >
            <SubTitle2>Existing Me Books User</SubTitle2>
            <SubBody style={{ marginTop: 30 }}>
              If a member has a monthly subscription through the Me Books
              website, redemption of the gift subscription will delay the next
              monthly payment until the end of the gift subscription duration.
            </SubBody>
            <SubBody>
              After redeeming the gift code, no further action is needed.
            </SubBody>
          </VerticalContainer>
        </BackgroundContainer>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps, null)(Support);

const Description = styled(SubBody)`
  width: 280px;
  text-align: left;
`;
