import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";
import App from "./App";
//Utils
import firebase from "./utils/firebase";
import * as serviceWorker from "./serviceWorker";
require("dotenv").config();

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
