import React, { Component } from "react";
import { connect } from "react-redux";
//Style
import { VerticalContainer, BackgroundContainer } from "../global/Layout";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import RedeemForm from "../components/RedeemForm";
import WindowResizer from "../components/WindowResizer";
//Assets
import DisplayVisual from "../assets/display-visual.jpg";

class NewUsersRedeem extends Component {
  handleNavigate = () => {
    this.props.history.push({
      pathname: "/action-success",
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  renderBackgroundHeight = () => {
    if (this.props.utilsStore.deviceType > 1) return "75vh";
    else if (this.props.utilsStore.deviceType > 0) return "70vh";
    else return "50vh";
  };

  render() {
    return (
      <>
        <WindowResizer />
        <Header />
        <BackgroundContainer>
          <VerticalContainer
            style={{
              backgroundImage: "url(" + DisplayVisual + ")",
              width: "100%",
              height: this.renderBackgroundHeight(),
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundColor: "#1d090a",
            }}
          />
          <RedeemForm type="new" handleNavigate={this.handleNavigate} />
        </BackgroundContainer>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(NewUsersRedeem);
