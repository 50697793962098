const initState = {
  deviceType: 2, //INFO 0 - Mobile, 1 - Tablet, 2 - Laptop
  deviceSize: 0,
  successType: {
    type: "",
  },
};

const utilsReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_DEVICE_TYPE":
      return {
        ...state,
        deviceType: action.payload.deviceType,
      };
    case "UPDATE_DEVICE_SIZE":
      return {
        ...state,
        deviceSize: action.payload.deviceSize,
      };
    case "UPDATE_SUCCESS_TYPE":
      return {
        ...state,
        successType: action.payload.successType,
      };
    default:
      return state;
  }
};

export default utilsReducer;
