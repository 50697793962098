import styled from "styled-components";
import { Black, CyanGreen } from "./Color";

export const BigTitle = styled.h1`
  font-family: "DoctorSoosBold";
  font-size: 43px;
  color: #fff;
`;

export const SubBigTitle = styled.h1`
  font-family: "AllisonScript";
  font-size: 43px;
  color: #fff;
`;

export const Title1 = styled.h1`
  font-family: "DoctorSoosBold";
  font-size: 38px;
  color: #fff;
`;

export const SpecTitle1 = styled.h1`
  font-family: "MuseoSansBold";
  font-size: 30px;
  color: #fff;
`;

export const Title2 = styled.h2`
  font-family: "DoctorSoosBold";
  font-size: 20px;
  color: #fff;
`;

export const SubTitle2 = styled(Title2)`
  font-family: "VisbyRound";
  font-weight: regular !important;
`;

export const Title3 = styled.h3`
  font-family: "DoctorSoosBold";
  font-size: 18px;
  color: #fff;
`;

export const SubTitle3 = styled(Title3)`
  font-family: "VisbyRound";
  font-size: 18px;
  font-weight: regular !important;
`;

export const Title4 = styled.h4`
  font-family: "DoctorSoosBold";
  font-size: 16px;
  color: #fff;
`;

export const SubTitle4 = styled(Title4)`
  font-family: "VisbyRound";
  font-size: 16px;
  font-weight: regular !important;
`;

export const Title5 = styled.h5`
  font-family: "DoctorSoosBold";
  font-size: 14px;
  color: #fff;
`;

export const SubTitle5 = styled(Title5)`
  font-family: "VisbyRound";
  font-weight: regular !important;
  font-size: 14px;
`;

export const Footnote = styled.h6`
  font-family: "VisbyRound";
  font-weight: regular !important;
  font-size: 12px;
  color: #fff;
`;

export const SubFootnote = styled(Footnote)`
  font-size: 10px;
`;

export const Body = styled.p`
  font-family: "DoctorSoosBold";
  font-size: 16px;
  color: #fff;
`;

export const SubBody = styled(Body)`
  font-family: "VisbyRound";
  font-weight: regular !important;
`;

export const Headline = styled.a`
  font-family: "DoctorSoosBold";
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  color: ${Black} !important;

  &:hover {
    color: ${CyanGreen} !important;
  }
`;
