import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import axios from "axios";
//Style
import { SubTitle2, SubTitle5 } from "../global/Typography";
import {
  VerticalContainer,
  Button,
  FormInput,
  FormItem,
  FormSelect,
  minMobileBreakpoints,
} from "../global/Layout";
import { Red, LightRed } from "../global/Color";
import { Select, notification } from "antd";
import Loading from "./Loading";
//Utils
import countryList from "../utils/countryList";
import stateList from "../utils/stateList";
import ReactGA from "react-ga";
//Actions
import { updateSuccessType } from "../store/actions/utilsActions";

const { Option } = Select;

class RedeemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      code: "",
      codeError: "",
      country: "",
      countryError: "",
      state: "",
      stateError: "",
      password: "",
      passwordError: "",
      passwordConfirm: "",
      passwordConfirmError: "",
      user: null,
      loading: false,
    };
  }

  handleSelectCountry = (value) => {
    this.setState({
      country: value,
    });
  };

  handleSelectState = (value) => {
    this.setState({
      state: value,
    });
  };

  handleLoading = (bool) => {
    this.setState({
      loading: bool,
    });
  };

  renderErrorMessage = (key) => {
    let message = [
      "Email Error",
      "Code Error",
      "Code Error",
      "Unknown Error",
      "User Existed",
    ];

    return message[key];
  };

  renderErrorDescription = (key) => {
    let description = [
      "The email cannot be found. Please recheck that you have entered the correct email",
      "This gift code cannot be found. Please recheck that you have entered the correct gift code",
      "This gift code has been used. Please try other gift code",
      "We are unsure with what error you are facing, if this problem still exists, please contact hello@mebooks.co",
      "This email account has been created. Please try a new email address",
    ];

    return description[key];
  };

  handleNotificationError = (key) => {
    notification.error({
      message: this.renderErrorMessage(key),
      description: this.renderErrorDescription(key),
      placement: "bottomRight",
    });
  };

  handleSubmit = (value) => {
    this.setState(
      {
        emailError: "",
        countryError: "",
        stateError: "",
        passwordConfirmError: "",
        codeError: "",
      },
      () => {
        this.handleCheckCondition("email");
        this.handleCheckCondition("code");
        if (value === "new") {
          if (this.state.code.includes("PNMMB"))
            this.handleCheckCondition("state");
          else this.handleCheckCondition("country");
          this.handleCheckCondition("password");
          this.handleCheckCondition("passwordConfirm");
        }
        this.handleLoading(true);
        setTimeout(async () => {
          if (
            this.state.emailError === "" &&
            this.state.countryError === "" &&
            this.state.stateError === "" &&
            this.state.passwordConfirmError === "" &&
            this.state.codeError === ""
          ) {
            try {
              let url;
              if (value === "existing")
                url =
                  "https://us-central1-mebooks-plus.cloudfunctions.net/redeemCodeExistingUser";
              else {
                if (this.state.code.includes("PNMMB"))
                  url =
                    "https://us-central1-mebooks-plus.cloudfunctions.net/redeemCodePNMNewUser";
                else
                  url =
                    "https://us-central1-mebooks-plus.cloudfunctions.net/redeemCodeNewUser";
              }

              let filteredCode = this.state.code;
              // let filteredCode = this.state.code.replace(/-/g, "");
              let response = await axios.post(url, {
                code: filteredCode,
                password: this.state.password,
                userData: {
                  email: this.state.email,
                  country: this.state.country ? this.state.country : "MY",
                  state: this.state.state ? this.state.state : "UKN",
                },
              });
              if (response && response.data) {
                this.handleLoading(false);

                let analyticsCategory = "";
                if (value === "existing")
                  analyticsCategory =
                    process.env.NODE_ENV === "development"
                      ? "RedeemExistingDev"
                      : "RedeemExisting";
                else
                  analyticsCategory =
                    process.env.NODE_ENV === "development"
                      ? "RedeemNewDev"
                      : "RedeemNew";
                ReactGA.event({
                  category: analyticsCategory,
                  action: `Redeem Code`,
                  label: `${filteredCode}`,
                });

                this.props.updateSuccessType({
                  type: value,
                  data: response.data,
                });
                this.props.handleNavigate();
              }
            } catch (err) {
              if (err.response.data.error.code === "USER_NOT_FOUND")
                this.setState(
                  {
                    emailError: this.renderErrorDescription(0),
                  },
                  () => {
                    this.handleNotificationError(0);
                  }
                );
              else if (err.response.data.error.code === "CODE_NOT_FOUND")
                this.setState(
                  {
                    codeError: this.renderErrorDescription(1),
                  },
                  () => {
                    this.handleNotificationError(1);
                  }
                );
              else if (err.response.data.error.code === "CODE_USED")
                this.setState(
                  {
                    codeError: this.renderErrorDescription(2),
                  },
                  () => {
                    this.handleNotificationError(2);
                  }
                );
              else if (err.response.data.error.code === "USER_EXISTED")
                this.setState(
                  {
                    emailError: this.renderErrorDescription(4),
                  },
                  () => {
                    this.handleNotificationError(4);
                  }
                );
              else this.handleNotificationError(3);
              this.handleLoading(false);
            }
          } else this.handleLoading(false);
        });
      }
    );
  };

  handleCheckCondition = (type) => {
    switch (type) {
      case "email":
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(this.state.email))
          this.setState({
            emailError: "Email has been registed/ Invalid email address.",
          });
        break;
      case "code":
        if (this.state.code.length <= 0)
          this.setState({
            codeError: "Invaild Gift Code. Please try again.",
          });
        break;
      case "country":
        if (this.state.country === "")
          this.setState({
            countryError: "Please select a country.",
          });
        break;
      case "state":
        if (this.state.state === "")
          this.setState({
            stateError: "Please select a state.",
          });
        break;
      case "password":
        if (
          this.state.password.includes(" ") ||
          this.state.password.includes(",") ||
          this.state.password.length < 6
        )
          this.setState({
            passwordError: "Please enter a valid password",
          });
        else
          this.setState({
            passwordError: "",
          });
        break;
      case "passwordConfirm":
        if (this.state.passwordConfirm !== this.state.password)
          this.setState({
            passwordConfirmError: "Please enter the same password",
          });
        break;
      default:
        break;
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  renderCountrySelection = () => {
    let listView = [];
    countryList.map((eachCountry, index) => {
      listView.push(
        <Option key={index} value={eachCountry.value}>
          {eachCountry.label}
        </Option>
      );
    });

    return listView;
  };

  renderStateSelection = () => {
    let listView = [];
    stateList.map((eachState, index) => {
      listView.push(
        <Option key={index} value={eachState.value}>
          {eachState.label}
        </Option>
      );
    });

    return listView;
  };

  renderFormAttributes = () => {
    return (
      <VerticalContainer>
        <SubTitle5>&nbsp;</SubTitle5>
        <FormItem
          name="email"
          hasFeedback={this.state.emailError === "" ? false : true}
          help={this.state.emailError}
          validateStatus={this.state.emailError === "" ? null : "error"}
        >
          <FormInput
            id="email"
            type="email"
            placeholder="hello@mebooks.co"
            onChange={this.handleChange}
            value={this.state.email}
          />
        </FormItem>

        {this.props.type === "new" && (
          <>
            <FormItem
              name="password"
              hasFeedback={this.state.passwordError === "" ? false : true}
              help={this.state.passwordError}
              validateStatus={this.state.passwordError === "" ? null : "error"}
            >
              <FormInput
                id="password"
                type="password"
                placeholder="Create A Password"
                onChange={this.handleChange}
                value={this.state.password}
              />
            </FormItem>

            <FormItem
              hasFeedback={
                this.state.passwordConfirmError === "" ? false : true
              }
              help={this.state.passwordConfirmError}
              validateStatus={
                this.state.passwordConfirmError === "" ? null : "error"
              }
            >
              <FormInput
                id="passwordConfirm"
                type="password"
                style={{ textAlign: "left" }}
                placeholder="Confirm Password"
                onChange={this.handleChange}
                value={this.state.passwordConfirm}
              />
            </FormItem>
          </>
        )}
      </VerticalContainer>
    );
  };

  renderRedeemAttributes = () => {
    return (
      <VerticalContainer>
        <SubTitle5>Enter your Gift Code Here</SubTitle5>
        <FormItem
          name="code"
          hasFeedback={this.state.codeError === "" ? false : true}
          help={this.state.codeError}
          validateStatus={this.state.codeError === "" ? null : "error"}
        >
          <FormInput
            id="code"
            type="code"
            style={{ textAlign: "center" }}
            placeholder="XXXX-XXXX-XXXX"
            onChange={this.handleChange}
            value={this.state.code}
          />
        </FormItem>

        {this.props.type === "new" ? (
          <>
            {this.state.code.includes("PNMMB") ? (
              <FormItem
                name="State"
                hasFeedback={this.state.stateError === "" ? false : true}
                help={this.state.stateError}
                validateStatus={this.state.stateError === "" ? null : "error"}
              >
                <FormSelect
                  id="state"
                  placeholder="State"
                  onChange={this.handleSelectState}
                >
                  {this.renderStateSelection()}
                </FormSelect>
              </FormItem>
            ) : (
              <FormItem
                name="Country"
                hasFeedback={this.state.countryError === "" ? false : true}
                help={this.state.countryError}
                validateStatus={this.state.countryError === "" ? null : "error"}
              >
                <FormSelect
                  id="country"
                  placeholder="Country"
                  onChange={this.handleSelectCountry}
                >
                  {this.renderCountrySelection()}
                </FormSelect>
              </FormItem>
            )}
            <GreenButton
              style={{
                marginTop: this.props.utilsStore.deviceType === 0 ? 10 : 60,
              }}
              onClick={this.handleSubmit.bind(this, "new")}
            >
              Redeem
            </GreenButton>
          </>
        ) : (
          <RedButton onClick={this.handleSubmit.bind(this, "existing")}>
            Add to Account
          </RedButton>
        )}
      </VerticalContainer>
    );
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        {this.state.loading && <Loading />}
        <SubTitle2 style={{ marginTop: 30 }}>
          {this.props.type === "new"
            ? "For New User"
            : "For Existing Me Books Users"}
        </SubTitle2>
        <VerticalContainer
          style={{
            width: "100%",
            marginBottom: "5%",
          }}
        >
          {this.renderFormAttributes()}
          {this.renderRedeemAttributes()}
        </VerticalContainer>
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSuccessType: (successType) =>
      dispatch(updateSuccessType(successType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedeemForm);

const GreenButton = styled(Button)`
  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    margin-bottom: 50px;
  }
`;

const RedButton = styled(GreenButton)`
  background-color: ${Red};

  &:hover {
    background-color: ${LightRed};
  }
`;
