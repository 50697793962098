import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import {
  Title1,
  SubTitle2,
  SubTitle4,
  SubTitle5,
  Headline,
  Footnote,
} from "../global/Typography";
import {
  FlexibleRowContainer,
  VerticalContainer,
  BackgroundContainer,
  Button,
  FormItem,
  FormInput,
  minTabletBreakpoints,
  minLaptopBreakpoints,
} from "../global/Layout";
import { Divider } from "antd";
import { Red, White } from "../global/Color";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import SuccessVisual from "../components/SuccessVisual";
import WindowResizer from "../components/WindowResizer";
import Stepper from "../components/Stepper";
//Assets
import twelvemonths from "../assets/newcard_twelvemonths.png";
import sixmonths from "../assets/newcard_sixmonths.png";
import threemonths from "../assets/newcard_threemonths.png";
import stripeLogo from "../assets/stripe-logo.png";
import meBooksLogo from "../assets/me-books-logo.png";
//Stripe
import StripeCheckout from "react-stripe-checkout";
//Utils
import axios from "axios";
import ReactGA from "react-ga";

const STRIPE_LIVE_KEY = "pk_live_RLkcGL5rQkaxaCuLR8QTNuuH000SUlTVx0";
const STRIPE_TEST_KEY = "pk_test_JdW9mPIQSmge7HcBau9i69O600xynle6v0";

let plans = [
  {
    image: twelvemonths,
    header: "Popular Choice",
    subHeader: "12-months Subscription Plan",
    content: "Save USD 18",
    caption: "Normal Price : US$ 48",
    type: "twelvemonths",
    title: "12-months",
    priceTitle: "USD 30",
    price: 30,
  },
  {
    image: sixmonths,
    header: null,
    subHeader: "6-months Subscription Plan",
    content: "Save USD 6",
    caption: "Normal Price : US$ 24",
    type: "sixmonths",
    title: "6-months",
    priceTitle: "USD 18",
    price: 18,
  },
  {
    image: threemonths,
    header: null,
    subHeader: "3-months Subscription Plan",
    content: "Save USD 2",
    caption: "Normal Price : US$ 12",
    type: "threemonths",
    title: "3-months",
    priceTitle: "USD 10",
    price: 10,
  },
];

class NewPurchase extends Component {
  state = {
    stepIndex: 0,
    email: "",
    emailError: "",
    name: "",
    nameError: "",
    recipientEmail: "",
    recipientEmailError: "",
    recipientName: "",
    recipientNameError: "",
    loading: false,
    user: null,
    selectedPlan: "",
    selectedPlanAttribute: {},
  };

  handlePurchase = async (token) => {
    try {
      this.setState({
        loading: true,
      });
      const amount = this.state.selectedPlanAttribute.price * 100;
      const description = this.state.selectedPlanAttribute.description;
      const type = this.state.selectedPlanAttribute.type;
      const bodyObject = {
        tokenId: token.id,
        email: this.state.email,
        name: token.name,
        description,
        amount,
        type,
        environment: process.env.NODE_ENV,
      };
      let response = await axios.post(
        "https://us-central1-mebooks-plus.cloudfunctions.net/handlePurchase",
        bodyObject
      );
      if (response.data && response.data.paymentSuccess)
        this.setState(
          {
            loading: false,
          },
          () => {
            this.handleStepIndex(this.state.stepIndex + 1);
            ReactGA.event({
              category:
                process.env.NODE_ENV === "development"
                  ? "PurchaseDev"
                  : "Purchase",
              action: `Purchased ${this.state.selectedPlanAttribute.type}`,
              label: `Price : USD $${this.state.selectedPlanAttribute.price}`,
            });
          }
        );
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  };

  handleLoadingStop = () => {
    this.setState({
      loading: false,
    });
  };

  handleRegister = () => {
    this.setState(
      {
        emailError: "",
        nameError: "",
        recipientEmailError: "",
        recipientNameError: "",
        loading: true,
      },
      () => {
        this.handleCheckCondition("email");
        this.handleCheckCondition("name");
        // this.handleCheckCondition("recipientEmail");
        // this.handleCheckCondition("recipientName");
        setTimeout(async () => {
          if (
            this.state.emailError === "" &&
            this.state.nameError === "" &&
            this.state.recipientEmailError === "" &&
            this.state.recipientNameError === ""
          ) {
            window.scrollTo({ top: 70, behavior: "smooth" });
            this.setState({
              stepIndex: 2,
            });
            this.handleLoadingStop();
          } else {
            this.handleLoadingStop();
          }
        });
      }
    );
  };

  handleCheckCondition = (type) => {
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    switch (type) {
      case "email":
        if (!filter.test(this.state.email))
          this.setState({
            emailError: "Please enter the correct email format",
          });
        break;
      case "recipientEmail":
        if (!filter.test(this.state.recipientEmail))
          this.setState({
            recipientEmailError: "Please enter the correct email format",
          });
        break;
      case "name":
        if (this.state.name <= 0)
          this.setState({
            nameError: "Please enter your name",
          });
        break;
      case "recipientName":
        if (this.state.recipientName <= 0)
          this.setState({
            recipientNameError: "Please enter the recipient's name",
          });
        break;
      default:
        break;
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleStepIndex = (index) => {
    window.scrollTo({ top: 70, behavior: "smooth" });
    this.setState({
      stepIndex: index,
    });
  };

  handleCardSelected = (details) => {
    this.setState({
      selectedPlan: details.type,
      selectedPlanAttribute: {
        price: details.price,
        description: details.subHeader,
        title: details.title,
        content: details.content,
        priceTitle: details.priceTitle,
        type: details.type,
      },
    });
  };

  renderCardChoices = (details) => {
    let planSelected = this.state.selectedPlan === details.type;

    return (
      <VerticalContainer
        style={
          this.props.utilsStore.deviceType === 0
            ? { cursor: "pointer", marginTop: 40 }
            : { cursor: "pointer" }
        }
        onClick={
          planSelected
            ? this.handleStepIndex.bind(this, this.state.stepIndex + 1)
            : this.handleCardSelected.bind(this, details)
        }
      >
        <img
          src={details.image}
          style={
            planSelected
              ? {
                  border: "5px solid #ff6f61",
                  borderRadius: 30,
                  width: "90%",
                }
              : {
                  width: "90%",
                }
          }
        />
        {planSelected ? (
          <PlanSelectionHeadline style={{ color: Red, marginTop: 15 }}>
            Proceed to Next Step
          </PlanSelectionHeadline>
        ) : (
          <>
            <PriceContainer>
              <SubTitle4 style={{ color: Red }}>{details.header}</SubTitle4>
              <SubTitle5>{details.subHeader}</SubTitle5>
              <SubTitle2
                style={{
                  fontWeight: "900",
                }}
              >
                {details.content}
              </SubTitle2>
              <Footnote>{details.caption}</Footnote>
            </PriceContainer>
            <PlanSelectionHeadline>Select</PlanSelectionHeadline>
          </>
        )}
      </VerticalContainer>
    );
  };

  renderContent() {
    switch (this.state.stepIndex) {
      case 0:
        return (
          <VerticalContainer style={{ width: "100%" }}>
            <SubTitle5 style={{ marginTop: 40 }}>
              Monthly Subscription
            </SubTitle5>
            <Title1>USD 3.99</Title1>
            <CustomDivider />
            <SubTitle2 style={{ fontWeight: "bold", textAlign: "center" }}>
              Select a Plan and Save more!
            </SubTitle2>

            <FlexibleRowContainer
              style={{
                marginTop: 30,
                width: "95%",
                maxWidth: 1000,
                alignItems:
                  this.props.utilsStore.deviceType === 0
                    ? "center"
                    : "flex-start",
                justifyContent:
                  this.props.utilsStore.deviceType === 0
                    ? "center"
                    : "space-around",
              }}
            >
              {this.props.utilsStore.deviceType === 0 ? (
                <>
                  {this.renderCardChoices(plans[0])}
                  {this.renderCardChoices(plans[1])}
                  {this.renderCardChoices(plans[2])}
                </>
              ) : (
                <>
                  {this.renderCardChoices(plans[2])}
                  {this.renderCardChoices(plans[0])}
                  {this.renderCardChoices(plans[1])}
                </>
              )}
            </FlexibleRowContainer>
            <FlexibleRowContainer style={{ marginTop: 60, marginBottom: 50 }}>
              <SubTitle4>For Bulk Licenses Purchase</SubTitle4>
              <CustomHeadline
                href="https://www.mebooks.co/contact-us"
                target="_blank"
                style={{ textDecoration: "underline", marginLeft: 5 }}
              >
                Contact Us
              </CustomHeadline>
            </FlexibleRowContainer>
          </VerticalContainer>
        );

      case 1:
        return (
          <VerticalContainer
            style={{ width: "100%", marginTop: 50, marginBottom: 50 }}
          >
            <SubTitle2>Insert Gift Details</SubTitle2>
            <FormItem
              name="name"
              hasFeedback={this.state.nameError === "" ? false : true}
              help={this.state.nameError}
              validateStatus={this.state.nameError === "" ? null : "error"}
            >
              <FormInput
                id="name"
                type="name"
                placeholder="Your Name"
                onChange={this.handleChange}
                value={this.state.name}
              />
            </FormItem>
            <FormItem
              name="email"
              hasFeedback={this.state.emailError === "" ? false : true}
              help={this.state.emailError}
              validateStatus={this.state.emailError === "" ? null : "error"}
            >
              <FormInput
                id="email"
                type="email"
                placeholder="Your Email"
                onChange={this.handleChange}
                value={this.state.email}
              />
            </FormItem>
            {/* <FormItem
              name="recipientName"
              hasFeedback={this.state.recipientNameError === "" ? false : true}
              help={this.state.recipientNameError}
              validateStatus={
                this.state.recipientNameError === "" ? null : "error"
              }
            >
              <FormInput
                id="recipientName"
                type="recipientName"
                placeholder="Recipient's Name"
                onChange={this.handleChange}
                value={this.state.recipientName}
              />
            </FormItem>
            <FormItem
              name="recipientEmail"
              hasFeedback={this.state.recipientEmailError === "" ? false : true}
              help={this.state.recipientEmailError}
              validateStatus={
                this.state.recipientEmailError === "" ? null : "error"
              }
            >
              <FormInput
                id="recipientEmail"
                type="recipientEmail"
                placeholder="Recipient's Email"
                onChange={this.handleChange}
                value={this.state.recipientEmail}
              />
            </FormItem> */}
            <Button style={{ width: 280 }} onClick={this.handleRegister}>
              Redeem
            </Button>
          </VerticalContainer>
        );
      case 2:
        return (
          <>
            <VerticalContainer style={{ width: "100%" }}>
              <SubTitle2 style={{ marginTop: 50 }}>Order Summary</SubTitle2>
              <SuccessVisual
                type="orderSummary"
                selectedPlanAttribute={this.state.selectedPlanAttribute}
              />
              <StripeCheckout
                image={meBooksLogo}
                email={this.state.email}
                amount={
                  this.state.selectedPlanAttribute.price
                    ? this.state.selectedPlanAttribute.price * 100
                    : 0
                }
                token={this.handlePurchase}
                stripeKey={
                  process.env.NODE_ENV === "development"
                    ? STRIPE_TEST_KEY
                    : STRIPE_LIVE_KEY
                }
              >
                <Button style={{ width: 280 }}>Confirm</Button>
              </StripeCheckout>

              <SubTitle5>
                Click confirm to proceed to payment.
                <br /> You will be redirected to Stripe Payment.
              </SubTitle5>
              <img
                src={stripeLogo}
                style={{ marginTop: 30, marginBottom: 10, width: 200 }}
                alt="Stripe Logo"
              />
              <CustomHeadline
                href="https://stripe.com/en-gb-my"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                Can I Trust Stripe?
              </CustomHeadline>
              <SubTitle5
                style={{
                  marginTop: 30,
                  marginBottom: 50,
                  maxWidth: "90%",
                  textAlign: "center",
                }}
              >
                Millions of companies in over 120 countries use Stripe to start,
                run, and scale their businesses.
              </SubTitle5>
            </VerticalContainer>
          </>
        );

      case 3:
        return (
          <VerticalContainer
            style={{ width: "100%", marginTop: 80, marginBottom: 50 }}
          >
            <SuccessVisual type="success" email={this.state.email} />
          </VerticalContainer>
        );
      default:
        break;
    }
  }

  render() {
    return (
      <>
        <WindowResizer />
        {this.state.loading && <Loading />}
        <Header />
        <BackgroundContainer>
          <VerticalContainer
            style={{ width: "70%", marginTop: 50, maxWidth: 800 }}
          >
            <Stepper
              current={this.state.stepIndex}
              steps={["Plans", "Details", "Payment", "Success"]}
            />
          </VerticalContainer>
          {this.renderContent()}
        </BackgroundContainer>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(NewPurchase);

const CustomHeadline = styled(Headline)`
  color: ${White} !important;
  margin-top: -5px;
`;

const PlanSelectionHeadline = styled(Headline)`
  color: ${White} !important;
  text-decoration: underline;

  &:hover {
    color: #ff6f61;
  }
`;

const CustomDivider = styled(Divider)`
  width: 180px !important;
  height: 2px !important;
  background-color: #ffffff;
`;

const PriceContainer = styled(VerticalContainer)`
  border: 1px solid ${White};
  border-radius: 20px;
  padding: 15px 35px;
  margin: 20px 0px;

  @media only screen and (max-width: ${minLaptopBreakpoints - 1}px) {
    padding: 15px 20px;
  }

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    padding: 15px 30px;
  }
`;
