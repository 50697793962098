export default [
  {
    id: 1,
    value: "AF",
    label: "Afghanistan"
  },
  {
    id: 2,
    value: "AX",
    label: "Aland Islands"
  },
  {
    id: 3,
    value: "AL",
    label: "Albania"
  },
  {
    id: 4,
    value: "DZ",
    label: "Algeria"
  },
  {
    id: 5,
    value: "AS",
    label: "American Samoa"
  },
  {
    id: 6,
    value: "AD",
    label: "Andorra"
  },
  {
    id: 7,
    value: "AO",
    label: "Angola"
  },
  {
    id: 8,
    value: "AI",
    label: "Anguilla"
  },
  {
    id: 9,
    value: "AQ",
    label: "Antarctica"
  },
  {
    id: 10,
    value: "AG",
    label: "Antigua And Barbuda"
  },
  {
    id: 11,
    value: "AR",
    label: "Argentina"
  },
  {
    id: 12,
    value: "AM",
    label: "Armenia"
  },
  {
    id: 13,
    value: "AW",
    label: "Aruba"
  },
  {
    id: 14,
    value: "AU",
    label: "Australia"
  },
  {
    id: 15,
    value: "AT",
    label: "Austria"
  },
  {
    id: 16,
    value: "AZ",
    label: "Azerbaijan"
  },
  {
    id: 17,
    value: "BS",
    label: "Bahamas"
  },
  {
    id: 18,
    value: "BH",
    label: "Bahrain"
  },
  {
    id: 19,
    value: "BD",
    label: "Bangladesh"
  },
  {
    id: 20,
    value: "BB",
    label: "Barbados"
  },
  {
    id: 21,
    value: "BY",
    label: "Belarus"
  },
  {
    id: 22,
    value: "BE",
    label: "Belgium"
  },
  {
    id: 23,
    value: "BZ",
    label: "Belize"
  },
  {
    id: 24,
    value: "BJ",
    label: "Benin"
  },
  {
    id: 25,
    value: "BM",
    label: "Bermuda"
  },
  {
    id: 26,
    value: "BT",
    label: "Bhutan"
  },
  {
    id: 27,
    value: "BO",
    label: "Bolivia"
  },
  {
    id: 28,
    value: "BA",
    label: "Bosnia And Herzegovina"
  },
  {
    id: 29,
    value: "BW",
    label: "Botswana"
  },
  {
    id: 30,
    value: "BV",
    label: "Bouvet Island"
  },
  {
    id: 31,
    value: "BR",
    label: "Brazil"
  },
  {
    id: 32,
    value: "IO",
    label: "British Indian Ocean Territory"
  },
  {
    id: 33,
    value: "BN",
    label: "Brunei Darussalam"
  },
  {
    id: 34,
    value: "BG",
    label: "Bulgaria"
  },
  {
    id: 35,
    value: "BF",
    label: "Burkina Faso"
  },
  {
    id: 36,
    value: "BI",
    label: "Burundi"
  },
  {
    id: 37,
    value: "KH",
    label: "Cambodia"
  },
  {
    id: 38,
    value: "CM",
    label: "Cameroon"
  },
  {
    id: 39,
    value: "CA",
    label: "Canada"
  },
  {
    id: 40,
    value: "CV",
    label: "Cape Verde"
  },
  {
    id: 41,
    value: "KY",
    label: "Cayman Islands"
  },
  {
    id: 42,
    value: "CF",
    label: "Central African Republic"
  },
  {
    id: 43,
    value: "TD",
    label: "Chad"
  },
  {
    id: 44,
    value: "CL",
    label: "Chile"
  },
  {
    id: 45,
    value: "CN",
    label: "China"
  },
  {
    id: 46,
    value: "CX",
    label: "Christmas Island"
  },
  {
    id: 47,
    value: "CC",
    label: "Cocos (Keeling) Islands"
  },
  {
    id: 48,
    value: "CO",
    label: "Colombia"
  },
  {
    id: 49,
    value: "KM",
    label: "Comoros"
  },
  {
    id: 50,
    value: "CG",
    label: "Congo"
  },
  {
    id: 51,
    value: "CD",
    label: "Congo, Democratic Republic"
  },
  {
    id: 52,
    value: "CK",
    label: "Cook Islands"
  },
  {
    id: 53,
    value: "CR",
    label: "Costa Rica"
  },
  {
    id: 54,
    value: "CI",
    label: "Cote D'Ivoire"
  },
  {
    id: 55,
    value: "HR",
    label: "Croatia"
  },
  {
    id: 56,
    value: "CU",
    label: "Cuba"
  },
  {
    id: 57,
    value: "CY",
    label: "Cyprus"
  },
  {
    id: 58,
    value: "CZ",
    label: "Czech Republic"
  },
  {
    id: 59,
    value: "DK",
    label: "Denmark"
  },
  {
    id: 60,
    value: "DJ",
    label: "Djibouti"
  },
  {
    id: 61,
    value: "DM",
    label: "Dominica"
  },
  {
    id: 62,
    value: "DO",
    label: "Dominican Republic"
  },
  {
    id: 63,
    value: "EC",
    label: "Ecuador"
  },
  {
    id: 64,
    value: "EG",
    label: "Egypt"
  },
  {
    id: 65,
    value: "SV",
    label: "El Salvador"
  },
  {
    id: 66,
    value: "GQ",
    label: "Equatorial Guinea"
  },
  {
    id: 67,
    value: "ER",
    label: "Eritrea"
  },
  {
    id: 68,
    value: "EE",
    label: "Estonia"
  },
  {
    id: 69,
    value: "ET",
    label: "Ethiopia"
  },
  {
    id: 70,
    value: "FK",
    label: "Falkland Islands (Malvinas)"
  },
  {
    id: 71,
    value: "FO",
    label: "Faroe Islands"
  },
  {
    id: 72,
    value: "FJ",
    label: "Fiji"
  },
  {
    id: 73,
    value: "FI",
    label: "Finland"
  },
  {
    id: 74,
    value: "FR",
    label: "France"
  },
  {
    id: 75,
    value: "GF",
    label: "French Guiana"
  },
  {
    id: 76,
    value: "PF",
    label: "French Polynesia"
  },
  {
    id: 77,
    value: "TF",
    label: "French Southern Territories"
  },
  {
    id: 78,
    value: "GA",
    label: "Gabon"
  },
  {
    id: 79,
    value: "GM",
    label: "Gambia"
  },
  {
    id: 80,
    value: "GE",
    label: "Georgia"
  },
  {
    id: 81,
    value: "DE",
    label: "Germany"
  },
  {
    id: 82,
    value: "GH",
    label: "Ghana"
  },
  {
    id: 83,
    value: "GI",
    label: "Gibraltar"
  },
  {
    id: 84,
    value: "GR",
    label: "Greece"
  },
  {
    id: 85,
    value: "GL",
    label: "Greenland"
  },
  {
    id: 86,
    value: "GD",
    label: "Grenada"
  },
  {
    id: 87,
    value: "GP",
    label: "Guadeloupe"
  },
  {
    id: 88,
    value: "GU",
    label: "Guam"
  },
  {
    id: 89,
    value: "GT",
    label: "Guatemala"
  },
  {
    id: 90,
    value: "GG",
    label: "Guernsey"
  },
  {
    id: 91,
    value: "GN",
    label: "Guinea"
  },
  {
    id: 92,
    value: "GW",
    label: "Guinea-Bissau"
  },
  {
    id: 93,
    value: "GY",
    label: "Guyana"
  },
  {
    id: 94,
    value: "HT",
    label: "Haiti"
  },
  {
    id: 95,
    value: "HM",
    label: "Heard Island & Mcdonald Islands"
  },
  {
    id: 96,
    value: "VA",
    label: "Holy See (Vatican City State)"
  },
  {
    id: 97,
    value: "HN",
    label: "Honduras"
  },
  {
    id: 98,
    value: "HK",
    label: "Hong Kong"
  },
  {
    id: 99,
    value: "HU",
    label: "Hungary"
  },
  {
    id: 100,
    value: "IS",
    label: "Iceland"
  },
  {
    id: 101,
    value: "IN",
    label: "India"
  },
  {
    id: 102,
    value: "ID",
    label: "Indonesia"
  },
  {
    id: 103,
    value: "IR",
    label: "Iran, Islamic Republic Of"
  },
  {
    id: 104,
    value: "IQ",
    label: "Iraq"
  },
  {
    id: 105,
    value: "IE",
    label: "Ireland"
  },
  {
    id: 106,
    value: "IM",
    label: "Isle Of Man"
  },
  {
    id: 107,
    value: "IL",
    label: "Israel"
  },
  {
    id: 108,
    value: "IT",
    label: "Italy"
  },
  {
    id: 109,
    value: "JM",
    label: "Jamaica"
  },
  {
    id: 110,
    value: "JP",
    label: "Japan"
  },
  {
    id: 111,
    value: "JE",
    label: "Jersey"
  },
  {
    id: 112,
    value: "JO",
    label: "Jordan"
  },
  {
    id: 113,
    value: "KZ",
    label: "Kazakhstan"
  },
  {
    id: 114,
    value: "KE",
    label: "Kenya"
  },
  {
    id: 115,
    value: "KI",
    label: "Kiribati"
  },
  {
    id: 116,
    value: "KR",
    label: "Korea"
  },
  {
    id: 117,
    value: "KW",
    label: "Kuwait"
  },
  {
    id: 118,
    value: "KG",
    label: "Kyrgyzstan"
  },
  {
    id: 119,
    value: "LA",
    label: "Lao People's Democratic Republic"
  },
  {
    id: 120,
    value: "LV",
    label: "Latvia"
  },
  {
    id: 121,
    value: "LB",
    label: "Lebanon"
  },
  {
    id: 122,
    value: "LS",
    label: "Lesotho"
  },
  {
    id: 123,
    value: "LR",
    label: "Liberia"
  },
  {
    id: 124,
    value: "LY",
    label: "Libyan Arab Jamahiriya"
  },
  {
    id: 125,
    value: "LI",
    label: "Liechtenstein"
  },
  {
    id: 126,
    value: "LT",
    label: "Lithuania"
  },
  {
    id: 127,
    value: "LU",
    label: "Luxembourg"
  },
  {
    id: 128,
    value: "MO",
    label: "Macao"
  },
  {
    id: 129,
    value: "MK",
    label: "Macedonia"
  },
  {
    id: 130,
    value: "MG",
    label: "Madagascar"
  },
  {
    id: 131,
    value: "MW",
    label: "Malawi"
  },
  {
    id: 132,
    value: "MY",
    label: "Malaysia"
  },
  {
    id: 133,
    value: "MV",
    label: "Maldives"
  },
  {
    id: 134,
    value: "ML",
    label: "Mali"
  },
  {
    id: 135,
    value: "MT",
    label: "Malta"
  },
  {
    id: 136,
    value: "MH",
    label: "Marshall Islands"
  },
  {
    id: 137,
    value: "MQ",
    label: "Martinique"
  },
  {
    id: 138,
    value: "MR",
    label: "Mauritania"
  },
  {
    id: 139,
    value: "MU",
    label: "Mauritius"
  },
  {
    id: 140,
    value: "YT",
    label: "Mayotte"
  },
  {
    id: 141,
    value: "MX",
    label: "Mexico"
  },
  {
    id: 142,
    value: "FM",
    label: "Micronesia, Federated States Of"
  },
  {
    id: 143,
    value: "MD",
    label: "Moldova"
  },
  {
    id: 144,
    value: "MC",
    label: "Monaco"
  },
  {
    id: 145,
    value: "MN",
    label: "Mongolia"
  },
  {
    id: 146,
    value: "ME",
    label: "Montenegro"
  },
  {
    id: 147,
    value: "MS",
    label: "Montserrat"
  },
  {
    id: 148,
    value: "MA",
    label: "Morocco"
  },
  {
    id: 149,
    value: "MZ",
    label: "Mozambique"
  },
  {
    id: 150,
    value: "MM",
    label: "Myanmar"
  },
  {
    id: 151,
    value: "NA",
    label: "Namibia"
  },
  {
    id: 152,
    value: "NR",
    label: "Nauru"
  },
  {
    id: 153,
    value: "NP",
    label: "Nepal"
  },
  {
    id: 154,
    value: "NL",
    label: "Netherlands"
  },
  {
    id: 155,
    value: "AN",
    label: "Netherlands Antilles"
  },
  {
    id: 156,
    value: "NC",
    label: "New Caledonia"
  },
  {
    id: 157,
    value: "NZ",
    label: "New Zealand"
  },
  {
    id: 158,
    value: "NI",
    label: "Nicaragua"
  },
  {
    id: 159,
    value: "NE",
    label: "Niger"
  },
  {
    id: 160,
    value: "NG",
    label: "Nigeria"
  },
  {
    id: 161,
    value: "NU",
    label: "Niue"
  },
  {
    id: 162,
    value: "NF",
    label: "Norfolk Island"
  },
  {
    id: 163,
    value: "MP",
    label: "Northern Mariana Islands"
  },
  {
    id: 164,
    value: "NO",
    label: "Norway"
  },
  {
    id: 165,
    value: "OM",
    label: "Oman"
  },
  {
    id: 166,
    value: "PK",
    label: "Pakistan"
  },
  {
    id: 167,
    value: "PW",
    label: "Palau"
  },
  {
    id: 168,
    value: "PS",
    label: "Palestinian Territory, Occupied"
  },
  {
    id: 169,
    value: "PA",
    label: "Panama"
  },
  {
    id: 170,
    value: "PG",
    label: "Papua New Guinea"
  },
  {
    id: 171,
    value: "PY",
    label: "Paraguay"
  },
  {
    id: 172,
    value: "PE",
    label: "Peru"
  },
  {
    id: 173,
    value: "PH",
    label: "Philippines"
  },
  {
    id: 174,
    value: "PN",
    label: "Pitcairn"
  },
  {
    id: 175,
    value: "PL",
    label: "Poland"
  },
  {
    id: 176,
    value: "PT",
    label: "Portugal"
  },
  {
    id: 177,
    value: "PR",
    label: "Puerto Rico"
  },
  {
    id: 178,
    value: "QA",
    label: "Qatar"
  },
  {
    id: 179,
    value: "RE",
    label: "Reunion"
  },
  {
    id: 180,
    value: "RO",
    label: "Romania"
  },
  {
    id: 181,
    value: "RU",
    label: "Russian Federation"
  },
  {
    id: 182,
    value: "RW",
    label: "Rwanda"
  },
  {
    id: 183,
    value: "BL",
    label: "Saint Barthelemy"
  },
  {
    id: 184,
    value: "SH",
    label: "Saint Helena"
  },
  {
    id: 185,
    value: "KN",
    label: "Saint Kitts And Nevis"
  },
  {
    id: 186,
    value: "LC",
    label: "Saint Lucia"
  },
  {
    id: 187,
    value: "MF",
    label: "Saint Martin"
  },
  {
    id: 188,
    value: "PM",
    label: "Saint Pierre And Miquelon"
  },
  {
    id: 189,
    value: "VC",
    label: "Saint Vincent And Grenadines"
  },
  {
    id: 190,
    value: "WS",
    label: "Samoa"
  },
  {
    id: 191,
    value: "SM",
    label: "San Marino"
  },
  {
    id: 192,
    value: "ST",
    label: "Sao Tome And Principe"
  },
  {
    id: 193,
    value: "SA",
    label: "Saudi Arabia"
  },
  {
    id: 194,
    value: "SN",
    label: "Senegal"
  },
  {
    id: 195,
    value: "RS",
    label: "Serbia"
  },
  {
    id: 196,
    value: "SC",
    label: "Seychelles"
  },
  {
    id: 197,
    value: "SL",
    label: "Sierra Leone"
  },
  {
    id: 198,
    value: "SG",
    label: "Singapore"
  },
  {
    id: 199,
    value: "SK",
    label: "Slovakia"
  },
  {
    id: 200,
    value: "SI",
    label: "Slovenia"
  },
  {
    id: 201,
    value: "SB",
    label: "Solomon Islands"
  },
  {
    id: 202,
    value: "SO",
    label: "Somalia"
  },
  {
    id: 203,
    value: "ZA",
    label: "South Africa"
  },
  {
    id: 204,
    value: "GS",
    label: "South Georgia And Sandwich Isl."
  },
  {
    id: 205,
    value: "ES",
    label: "Spain"
  },
  {
    id: 206,
    value: "LK",
    label: "Sri Lanka"
  },
  {
    id: 207,
    value: "SD",
    label: "Sudan"
  },
  {
    id: 208,
    value: "SR",
    label: "Surilabel"
  },
  {
    id: 209,
    value: "SJ",
    label: "Svalbard And Jan Mayen"
  },
  {
    id: 210,
    value: "SZ",
    label: "Swaziland"
  },
  {
    id: 211,
    value: "SE",
    label: "Sweden"
  },
  {
    id: 212,
    value: "CH",
    label: "Switzerland"
  },
  {
    id: 213,
    value: "SY",
    label: "Syrian Arab Republic"
  },
  {
    id: 214,
    value: "TW",
    label: "Taiwan"
  },
  {
    id: 215,
    value: "TJ",
    label: "Tajikistan"
  },
  {
    id: 216,
    value: "TZ",
    label: "Tanzania"
  },
  {
    id: 217,
    value: "TH",
    label: "Thailand"
  },
  {
    id: 218,
    value: "TL",
    label: "Timor-Leste"
  },
  {
    id: 219,
    value: "TG",
    label: "Togo"
  },
  {
    id: 220,
    value: "TK",
    label: "Tokelau"
  },
  {
    id: 221,
    value: "TO",
    label: "Tonga"
  },
  {
    id: 222,
    value: "TT",
    label: "Trinidad And Tobago"
  },
  {
    id: 223,
    value: "TN",
    label: "Tunisia"
  },
  {
    id: 224,
    value: "TR",
    label: "Turkey"
  },
  {
    id: 225,
    value: "TM",
    label: "Turkmenistan"
  },
  {
    id: 226,
    value: "TC",
    label: "Turks And Caicos Islands"
  },
  {
    id: 227,
    value: "TV",
    label: "Tuvalu"
  },
  {
    id: 228,
    value: "UG",
    label: "Uganda"
  },
  {
    id: 229,
    value: "UA",
    label: "Ukraine"
  },
  {
    id: 230,
    value: "AE",
    label: "United Arab Emirates"
  },
  {
    id: 231,
    value: "GB",
    label: "United Kingdom"
  },
  {
    id: 232,
    value: "US",
    label: "United States"
  },
  {
    id: 233,
    value: "UM",
    label: "United States Outlying Islands"
  },
  {
    id: 234,
    value: "UY",
    label: "Uruguay"
  },
  {
    id: 235,
    value: "UZ",
    label: "Uzbekistan"
  },
  {
    id: 236,
    value: "VU",
    label: "Vanuatu"
  },
  {
    id: 237,
    value: "VE",
    label: "Venezuela"
  },
  {
    id: 238,
    value: "VN",
    label: "Viet Nam"
  },
  {
    id: 239,
    value: "VG",
    label: "Virgin Islands, British"
  },
  {
    id: 240,
    value: "VI",
    label: "Virgin Islands, U.S."
  },
  {
    id: 241,
    value: "WF",
    label: "Wallis And Futuna"
  },
  {
    id: 242,
    value: "EH",
    label: "Western Sahara"
  },
  {
    id: 243,
    value: "YE",
    label: "Yemen"
  },
  {
    id: 244,
    value: "ZM",
    label: "Zambia"
  },
  {
    id: 245,
    value: "ZW",
    label: "Zimbabwe"
  }
];
