import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import { SpecTitle1, SubTitle2 } from "../global/Typography";
import {
  Button,
  BackgroundContainer,
  FormItem,
  FormInput,
} from "../global/Layout";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import WindowResizer from "../components/WindowResizer";
//Utils
import firebase from "../utils/firebase";

class ResetPassword extends Component {
  state = {
    oobCode: "",
    email: "",
    success: false,
    loading: false,
    password: "",
    passwordError: "",
    verificationError: "",
  };

  componentDidMount() {
    let mode = this.handleGetParameterByName(
      "mode",
      this.props.location.search
    );
    let oobCode = this.handleGetParameterByName(
      "oobCode",
      this.props.location.search
    );

    if (mode === "resetPassword" && oobCode)
      this.setState(
        {
          oobCode: oobCode,
        },
        async () => {
          try {
            let email = await firebase
              .auth()
              .verifyPasswordResetCode(this.state.oobCode);
            this.setState({
              email: email,
            });
          } catch (err) {
            this.setState({
              verificationError: err.message,
            });
          }
        }
      );
  }

  handleResetPassword = () => {
    if (this.state.password.length < 6)
      this.setState({
        passwordError: "Password must be at least 6 characters",
      });
    else
      this.setState(
        {
          loading: true,
        },
        async () => {
          try {
            await firebase
              .auth()
              .confirmPasswordReset(this.state.oobCode, this.state.password);
            this.setState({
              loading: false,
              success: true,
            });
          } catch (err) {
            this.setState({
              loading: false,
              passwordError: "Try a new password",
            });
          }
        }
      );
  };

  handleGetParameterByName(name, url) {
    if (url) {
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleAction = (type) => {
    if (type === "redeem") {
      this.props.history.push("/redeem");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (type === "purchase") {
      this.props.history.push("/purchase");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  renderResetPasswordAction = () => {
    if (!this.state.oobCode)
      return (
        <SubTitle2 style={{ marginTop: 20 }}>This code is invalid</SubTitle2>
      );
    else if (this.state.verificationError)
      return (
        <SubTitle2 style={{ marginTop: 20 }}>
          {this.state.verificationError}
        </SubTitle2>
      );
    else if (this.state.success)
      return (
        <SubTitle2 style={{ marginTop: 20 }}>
          Password of {this.state.email} has been successfully changed
        </SubTitle2>
      );
    else
      return (
        <>
          <SubTitle2 style={{ margin: 20 }}>
            Changing password of {this.state.email}
          </SubTitle2>
          <FormItem
            style={{
              width: this.props.utilsStore.deviceType === 0 ? 280 : 400,
            }}
            hasFeedback={this.state.passwordError === "" ? false : true}
            help={this.state.passwordError}
            validateStatus={this.state.passwordError === "" ? null : "error"}
          >
            <FormInput
              style={{ textAlign: "center" }}
              id="password"
              type="password"
              placeholder="New Password"
              onChange={this.handleChange}
              value={this.state.password}
            />
          </FormItem>
          <RedButton onClick={this.handleResetPassword}>
            Reset Password
          </RedButton>
        </>
      );
  };

  render() {
    return (
      <>
        <WindowResizer />
        {this.state.loading && <Loading />}
        <Header handleAction={this.handleAction} />
        <BackgroundContainer style={{ paddingTop: 100, paddingBottom: 100 }}>
          <SpecTitle1>Reset Your Password</SpecTitle1>
          {this.renderResetPasswordAction()}
        </BackgroundContainer>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(ResetPassword);

const RedButton = styled(Button)`
  background-color: #ff6f61 !important;

  &:hover {
    background-color: #f5857a !important;
  }
`;
