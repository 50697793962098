import React, { Component } from "react";
import { Divider } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
//Style
import {
  VerticalContainer,
  BackgroundContainer,
  Button,
  minLaptopBreakpoints,
  minTabletBreakpoints,
  minMobileBreakpoints,
} from "../global/Layout";
import { SubTitle3 } from "../global/Typography";
import { Black, White } from "../global/Color";
//Assets
import Background from "../assets/login-cover.jpg";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";

class HomePage extends Component {
  handleNavigate = (value) => {
    switch (value) {
      case "redeemNewUser":
        this.props.history.push("/redeem-newusers");
        break;
      case "newPurchase":
        this.props.history.push("/new-purchase");
        break;
      case "redeemExisting":
        this.props.history.push("/redeem-existing");
        break;
      case "support":
        this.props.history.push("/support");
        break;
      default:
        break;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  renderContent = () => {
    return (
      <>
        <SubTitle3
          style={{
            color: this.props.utilsStore.deviceType === 0 ? White : Black,
            fontWeight: "bold",
          }}
        >
          For New User
        </SubTitle3>
        <GreenButton
          style={{ lineHeight: 1 }}
          onClick={this.handleNavigate.bind(this, "newPurchase")}
        >
          Purchase a Gift Subscription or Subscribe here
        </GreenButton>
        <GreenButton onClick={this.handleNavigate.bind(this, "redeemNewUser")}>
          Redeem Your Gift Subscription
        </GreenButton>
        <CustomDivider />
        <SubTitle3
          style={{
            color: this.props.utilsStore.deviceType === 0 ? White : Black,
            fontWeight: "bold",
          }}
        >
          For Existing Me Books User
        </SubTitle3>
        <RedButton
          style={{ backgroundColor: "" }}
          onClick={this.handleNavigate.bind(this, "redeemExisting")}
        >
          Add to Your Existing Account
        </RedButton>
      </>
    );
  };

  renderBackgroundAttributes = (attribute) => {
    if (attribute === "backgroundSize") {
      if (this.props.utilsStore.deviceSize >= minLaptopBreakpoints)
        return "contain";
      else if (this.props.utilsStore.deviceSize >= minTabletBreakpoints)
        return "cover";
      else return "contain";
    } else if (attribute === "margin") {
      if (this.props.utilsStore.deviceSize >= minLaptopBreakpoints) return 250;
      else return this.props.utilsStore.deviceSize * 0.25;
    } else return this.props.utilsStore.deviceSize / 1.319;
  };

  render() {
    return (
      <>
        <WindowResizer />
        <Header />
        <BackgroundContainer>
          <VerticalContainer
            style={{
              backgroundImage: "url(" + Background + ")",
              backgroundSize: this.renderBackgroundAttributes("backgroundSize"),
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: this.renderBackgroundAttributes(),
              maxHeight: 1000,
              backgroundColor: "#200E0D",
            }}
          >
            {this.props.utilsStore.deviceType !== 0 && (
              <VerticalContainer
                style={{
                  marginTop: this.renderBackgroundAttributes("margin"),
                }}
              >
                {this.renderContent()}
              </VerticalContainer>
            )}
          </VerticalContainer>
          {this.props.utilsStore.deviceType === 0 && (
            <VerticalContainer style={{ marginTop: 30, marginBottom: 50 }}>
              {this.renderContent()}
              {/* <Footnote style={{ marginTop: 10, textAlign: "center" }}>
                If you are currently subscribed through Apple,
              </Footnote>
              <RowContainer>
                <Footnote>
                  you will need to turn off Apple's auto-renew. &nbsp;
                </Footnote>
                <a
                  href="https://support.apple.com/en-us/HT202039"
                  target="_blank"
                >
                  <TitleLink
                    style={{
                      textDecoration: "underline",
                    }}
                    onClick={this.handleNavigate.bind(this, "support")}
                  >
                    Learn how.
                  </TitleLink>
                </a>
              </RowContainer> */}
            </VerticalContainer>
          )}
        </BackgroundContainer>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(HomePage);

const CustomDivider = styled(Divider)`
  width: 15% !important;
  height: 2px !important;
  background-color: #ffffff;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    width: 50% !important;
  }
`;

const GreenButton = styled(Button)`
  width: 300px;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    width: 280px;
    font-size: 20px;
  }
`;

const RedButton = styled(GreenButton)`
  background-color: #ff6f61 !important;

  &:hover {
    background-color: #f5857a !important;
  }
`;
