import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Footer from "../components/Footer";
//Components
import Header from "../components/Header";
import WindowResizer from "../components/WindowResizer";
import { BackgroundContainer, Button } from "../global/Layout";
//Styles
import { SpecTitle1, SubTitle3, SubTitle4 } from "../global/Typography";

class NotFoundPage extends Component {
  handleAction = () => {
    this.props.history.push("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    const notFoundDescription1 = "We looked everywhere for this page.";
    const notFoundDescription2 = "Are you sure the website URL is correct?";

    return (
      <>
        <WindowResizer />
        <Header handleAction={this.handleAction} />
        <BackgroundContainer style={{ paddingTop: 100, paddingBottom: 100 }}>
          <SpecTitle1 style={{ textAlign: "center" }}>
            404 - Page Not Found
          </SpecTitle1>
          {this.props.utilsStore.deviceType === 0 ? (
            <SubTitle4>
              {notFoundDescription1}
              <br />
              {notFoundDescription2}
            </SubTitle4>
          ) : (
            <SubTitle3>
              {notFoundDescription1}
              <br />
              {notFoundDescription2}
            </SubTitle3>
          )}
          <RedButton onClick={this.handleAction}>Back To Home</RedButton>
        </BackgroundContainer>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(NotFoundPage);

const RedButton = styled(Button)`
  background-color: #ff6f61 !important;
  width: 280px;
  margin-top: 20px;

  &:hover {
    background-color: #f5857a !important;
  }
`;
